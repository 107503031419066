<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>客户名称：</span>
          <!--          <Select class="iviewIptWidth250 marginRight60" filterable v-model="supplierIndex" disabled>-->
          <!--            <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>-->
          <!--          </Select>-->
          <i-input class="iviewIptWidth250 marginRight60" placeholder="请输入" :value="detailsInfo.customer_name" disabled></i-input>
        </FormItem>
        <FormItem>
          <span style="margin-left: 18px;">经手人：</span>
          <!--          <Select class="iviewIptWidth250 marginRight60" v-model="queryFrom.handled_by" clearable filterable disabled>-->
          <!--            <Option v-for="item in handledByList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>-->
          <!--          </Select>-->
          <i-input class="iviewIptWidth250 marginRight60" placeholder="请输入" v-model="detailsInfo.handled_by_name" disabled></i-input>
        </FormItem>
        <!--        <FormItem>-->
        <!--          <span>产品名称：</span>-->
        <!--          <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" v-model="queryFrom.product_id" clearable filterable>-->
        <!--            <Option v-for="item in productList" :value="item.product_id" :key="item.product_id">{{ item.product_name }}</Option>-->
        <!--          </Select>-->
        <!--        </FormItem>-->
        <!--        <FormItem>-->
        <!--          <span>规格型号/SKU：</span>-->
        <!--          <Select class="iviewIptWidth250" :disabled="!queryFrom.product_id" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="1">-->
        <!--            <Option v-for="item in modelList" :value="item.code_id" :key="item.code_id + item.model_name" :label="item.model_name + '-' + item.item_number">-->
        <!--              <span>{{ item.item_number }}</span>-->
        <!--              <span style="float: right; color: #ccc; margin-right: 20px">{{ item.model_name }}</span>-->
        <!--            </Option>-->
        <!--          </Select>-->
        <!--        </FormItem>-->
        <!--        <FormItem class="po-create-number marginRight0" :label-width="10">-->
        <!--          <span class="pageBtn finger btnSure" @click="queryProductLookup">查询</span>-->
        <!--        </FormItem>-->
      </Form>
      <Table :columns="listColumns" :data="meterList" border no-data-text="暂无产品" :loading="loading"> </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0 && !loading">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
      <div class="other">其它信息</div>
      <div class="fromDiv">
        <div class="formLine borderTopNone">
          <FormLabel label="备注信息"></FormLabel>
          <div class="allWidth fl nowrap">{{ detailsInfo.remark ? detailsInfo.remark : '' }}</div>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormLabel from '@/components/formLabel'
export default {
  name: 'addPurchaseRequisition',
  components: {
    FormLabel,
  },
  computed: {
    ...mapState(['clientNameList']),
  },
  data() {
    return {
      supplierIndex: -1,
      supplierId: '',
      postStatus: false,
      deleteStatus: false,
      queryFrom: {
        handled_by: '',
        product_id: '',
        product_code_array: [],
      },
      postFrom: {
        handled_by: '',
        customer_info: {},
        product_info: [],
      },
      handledByList: [], // 经手人
      supplierList: [], // 客户名称
      productList: [],
      modelList: [], // sku列表
      storeList: [],
      meterList: [], // 已选产品列表
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 115,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 115,
        },
        {
          title: '厂家',
          key: 'factory_name',
          align: 'center',
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 85,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 114,
        },
        {
          title: '销售报价(含税)',
          key: 'sell_quote',
          align: 'center',
          width: 170,
        },
      ],
      clickIndex: Number, // 暂存点击删除数据下标
      id: '',
      detailsInfo: {},
      loading: true,
    }
  },
  mounted() {
    this.querySpecialUser()
    this.queryList()
    this.id = this.$route.query.id
    this.saleQuotationOperate()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    saleQuotationOperate() {
      this.loading = true
      this.$http.get(this.$api.saleQuotationOperate, { quotation_code: this.id }, true).then(res => {
        this.detailsInfo = res.data
        this.meterList = res.data.quotation_detail
        this.loading = false
        for (let i = 0; i < this.meterList.length; i++) {
          this.meterList[i].name = this.meterList[i].product_name
          this.meterList[i].licence_code = this.meterList[i].product_licence_code
          this.meterList[i].item_number = this.meterList[i].product_item_number
          this.meterList[i].unit = this.meterList[i].product_unit
          this.meterList[i].packing_size = this.meterList[i].product_packing_size
          this.meterList[i].model_name = this.meterList[i].product_model_name
        }
      })
    },
    // 查询经手人和审核人
    querySpecialUser() {
      this.$http.get(this.$api.publicSpecialUser, { user_type: 1 }, true).then(res => {
        this.handledByList = res.data
      })
    },
    // 查询仓库列表
    queryList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
.other {
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #525b6d;
  line-height: 25px;
  margin: 20px 0;
}
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iviewIptWidth270 {
  width: 290px !important;
}
.fromDiv .borderTopNone {
  border-top: 1px solid #e8eaec !important;
}
</style>
